import CaptureDescription from './CaptureDescription';
import CaptureTitle from './CaptureTitle';
import CircularProgress from '@mui/material/CircularProgress';
import { useLiveQuery } from 'dexie-react-hooks';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEntityConfigStore } from 'src/stores/entityConfig';
import { useUploadQueueStore } from 'src/stores/uploadQueue';
import { analytics } from 'src/texts/texts';

import { dexieDb, handleError } from 'src/utils/indexedDb';

import BaseButton from 'src/components/BaseButton/BaseButton';
import ContentView from 'src/components/Content/Content';
import Footer from 'src/components/Footer/Footer';
import DamageHeader from 'src/components/Header/DamageHeader';

import { eventNames } from 'src/utils/events';
import * as log from 'src/utils/logger';

import CaptureFeedback from '../CaptureFeedback';

type Props = {
  imageNumber: number;
  handleNext?: () => void;
  handleFinish: () => void;
  totalPics: number;
};

const CaptureImage = ({
  imageNumber,
  handleNext,
  totalPics,
  handleFinish,
}: Props) => {
  const { roomId } = useParams();
  const [loading, setLoading] = useState(false);
  const entityConfig = useEntityConfigStore((state) => state.config);
  const addImgToQueue = useUploadQueueStore((state) => state.add);

  const photo = useLiveQuery(
    async () => dexieDb.pictures.get(imageNumber),
    [imageNumber]
  );

  const totalPhotos = async () => {
    try {
      const photos = await dexieDb.pictures
        .where({ roomId: roomId })
        .filter((e) => e.imgType === 'overviewImage')
        .toArray();
      return (
        photos?.reduce((acc, curr) => {
          return acc + 1 + (curr.extraImages?.length || 0);
        }, 0) || 0
      );
    } catch (e) {
      handleError(e);
    }
  };

  const onImageUpload = async (data: string) => {
    setLoading(true);
    const errors: string[] = [];
    const uploadId = (await totalPhotos()) || 0;
    try {
      await dexieDb.pictures.add({
        id: imageNumber,
        uploadId: uploadId + 1,
        content: data,
        imgType: 'overviewImage',
        roomId,
        errors,
      });
    } catch (e) {
      handleError(e);
    }
    log.info({ event: 'Photo Capture', data: { imageNumber } });
    setLoading(false);
  };

  if (photo?.content) {
    return (
      <CaptureFeedback
        imageData={photo.content}
        imageNumber={imageNumber}
        onComplete={(id) => {
          navigator.onLine &&
            addImgToQueue({ id, abortController: new AbortController() });
          log.hosta({
            event: eventNames.ROOM_PHOTO_CAPTURED,
            data: { imageNumber: id },
          });
          handleNext?.();
        }}
      />
    );
  }

  return (
    <>
      <DamageHeader />
      <ContentView className="pb-48">
        {loading && (
          <div className="flex flex-col w-full h-screen justify-center items-center">
            <CircularProgress />
            <h6 className="pt-4">Processing Photo</h6>
          </div>
        )}
        {!loading && (
          <>
            <CaptureTitle imageNumber={imageNumber} />
            <CaptureDescription imageNumber={imageNumber} />
            <Footer>
              {totalPics >= 16 && <p>Maximum Upload Limit Reached</p>}
              {totalPics < 16 && (
                <BaseButton
                  lightBg
                  type="capture"
                  dataTestId="take-wall-photo-btn"
                  onCapture={onImageUpload}
                  icon="/icons/camera@2x.png"
                  name={analytics.nextImageCapture}
                  label="take wall photo"
                  capFirstLetterOnly={entityConfig.capFirstLetterOnly}
                />
              )}
              {imageNumber > 4 && (
                <BaseButton
                  dataTestId="complete-room-capture-btn"
                  label="complete room capture"
                  onClick={handleFinish}
                  capFirstLetterOnly={entityConfig.capFirstLetterOnly}
                />
              )}
            </Footer>
          </>
        )}
      </ContentView>
    </>
  );
};

export default CaptureImage;
