import * as Sentry from '@sentry/react';
import { useLiveQuery } from 'dexie-react-hooks';
import flagsmith from 'flagsmith';
import React, { PropsWithChildren, useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { addContactInfo, submitRoom, postCapture } from 'src/REST/capture';
import { useClaimInfoStore } from 'src/stores/claimInfo';
import { useUploadQueueStore } from 'src/stores/uploadQueue';
import { useLocalStorage } from 'usehooks-ts';

import {
  dexieDb,
  imagesUploaded,
  validateIndexedDBFields,
} from 'src/utils/indexedDb';

import Modal from 'src/components/Modal/Modal';
import PhotosLoading from 'src/components/PhotosLoading/PhotosLoading';

import { eventNames } from 'src/utils/events';
import * as log from 'src/utils/logger';
import { uploadRoomImage } from 'src/utils/uploadImage';

function organizeDamageData(damages: any) {
  const result: Record<string, { zoomedOut?: number; zoomedIn?: number }> = {};
  damages.forEach((damage: any) => {
    const { damageId, key, imgType } = damage;

    // Create or update the damageId entry
    if (!result[damageId]) {
      result[damageId] = {};
    }

    // Map type to either 'zoomedOut' or 'zoomedIn'
    if (imgType === 'damage-zoomed-out') {
      result[damageId].zoomedOut = key;
    } else if (imgType === 'damage-zoomed-in') {
      result[damageId].zoomedIn = key;
    }
  });

  return result;
}

const ConfirmPhotosPage: React.FC<PropsWithChildren> = () => {
  const params = useParams();
  const navigate = useNavigate();
  const claimInfo = useClaimInfoStore();
  const uploadQueue = useUploadQueueStore((state) => state.eventQueue);
  const bgUploaderInProgress = useRef(false);
  const [load, setLoad] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [badDBError, setBadDBError] = useState<string | null>();
  const [, setCachedRoomId] = useLocalStorage<string | null>(
    'cachedRoomId',
    null
  );
  const recaptureInfo = useClaimInfoStore((state) => state.recaptureInfo);
  const setCaptureId = useClaimInfoStore((state) => state.setCaptureId);
  const setIsRoomSubmitted = useClaimInfoStore(
    (state) => state.setIsRoomSubmitted
  );
  const isRoomSubmitted = useClaimInfoStore((state) => state.isRoomSubmitted);
  const recaptureKey = recaptureInfo?.isRecapture
    ? 'RECAPTURE_' + recaptureInfo?.rejectionCount
    : undefined;

  const pictures = useLiveQuery(
    async () =>
      dexieDb.pictures
        .where('roomId')
        .equals(params.roomId || '')
        .toArray(),
    [params.roomId]
  );

  useEffect(() => {
    if (pictures && !load && !error) {
      const imgsUploaded = imagesUploaded(pictures);

      if (imgsUploaded) {
        if (recaptureInfo?.isRecapture || !isRoomSubmitted) {
          onNextButtonClick();
        } else {
          setLoad(true);
        }
      } else {
        onNextButtonClick();
      }
    }
    // eslint-disable-next-line
  }, [load, pictures, error, isRoomSubmitted, navigator.onLine]);

  useEffect(() => {
    if (load && !uploadQueue.length && bgUploaderInProgress.current) {
      bgUploaderInProgress.current = false;
      onNextButtonClick();
    }
    // eslint-disable-next-line
  }, [uploadQueue, load]);

  const onNextButtonClick = () => {
    let start = performance.now();
    const failed: any[] = [];
    const uploaded: any[] = [];

    if (!navigator.onLine) {
      setCachedRoomId(params.roomId || null);
      setError(
        'No Internet! Please try again when you have internet connection'
      );
      return;
    }

    // If there are no photos to process redirect to the next page.
    if (!pictures?.length) {
      navigate(
        `/${params.captureType}/${params.claimId}/${params.roomId}/room-submitted`
      );
      return;
    }

    setLoad(true);

    if (uploadQueue.length && !bgUploaderInProgress.current) {
      bgUploaderInProgress.current = true;
      return;
    }

    (async () => {
      const pics = await dexieDb.pictures
        .where('roomId')
        .equals(params.roomId || '')
        .toArray();
      const wallPhotos = pics.filter((e) => e.imgType === 'overviewImage');
      const damagesPhotos = pics.filter((e) => e.imgType !== 'overviewImage');
      const validWallPhotos = validateIndexedDBFields(wallPhotos);

      if (!validWallPhotos) {
        setLoad(false);
        setBadDBError(
          'An issue occurred during the capture. Please restart the capture process.'
        );
        return;
      }

      for (const index in wallPhotos) {
        const img = wallPhotos[index];
        if (img.uploaded) {
          uploaded.push({ ...img, id: img.uploadId, key: img?.awsKey });
          if (img.extraImages?.length) {
            uploaded.push({
              ...img,
              id: (img.uploadId || 0) + 1,
              key: img.extraAWSKey,
            });
          }
        } else {
          try {
            const res = await uploadRoomImage({
              imgId: img.id,
              claimId: params.claimId || '',
              roomId: params.roomId || '',
              recaptureKey,
            });

            uploaded.push({
              ...img,
              id: img?.uploadId?.toString(),
              ...(res.image as {}),
            });

            if (res?.extraImage) {
              uploaded.push({
                id: ((img.uploadId || 0) + 1).toString(),
                ...(res.extraImage as {}),
              });
            }
          } catch (e) {
            failed.push(img);
            Sentry.captureException(e);
          }
        }
      }

      for (const img of damagesPhotos) {
        try {
          if (img.uploaded) {
            uploaded.push({ ...img, id: img.uploadId, key: img?.awsKey });
          } else {
            const res = await uploadRoomImage({
              imgId: img.id,
              claimId: params.claimId || '',
              roomId: params.roomId || '',
            });
            uploaded.push({
              ...img,
              ...(res.image as {}),
            });
            await dexieDb.pictures.update(img.id, {
              uploaded: true,
            });
          }
        } catch (e) {
          failed.push(img);
          Sentry.captureException(e);
        }
      }

      if (!failed.length) {
        try {
          const uploadsTracked = recaptureInfo?.isRecapture ? false : undefined;
          // Do not submit the room if is layout capture POC
          if (!isRoomSubmitted) {
            const additionalImages = !claimInfo?.allowDamage
              ? uploaded
                  .filter((img) => img?.imgType?.includes('damage'))
                  .map((img) => img.key)
              : [];
            const overviewImages = uploaded
              .filter((img) => img?.imgType === 'overviewImage')
              .map((img) => img.key);
            const damageImages = claimInfo?.allowDamage
              ? organizeDamageData(
                  uploaded.filter((img) => img?.imgType?.includes('damage'))
                )
              : {};

            await postCapture(params.claimId || '', params.roomId || '', {
              additionalImages,
              overviewImages,
              damageImages,
            });
            await submitRoom(
              params.claimId || '',
              params.roomId || '',
              'Living',
              uploaded.length,
              uploadsTracked
            );

            setIsRoomSubmitted(true);
          }
          if (
            claimInfo?.contactInfo &&
            claimInfo?.contactInfo !== '' &&
            params.claimId
          )
            await addContactInfo(params.claimId, claimInfo.contactInfo);
          const elapsedTime = Math.floor(
            ((performance.now() - start) / 1000) % 60
          );
          log.success({
            event: eventNames.SUBMIT_ROOM_SUCCESS,
            data: {
              uploaded,
              header: 'Room Submitted :rocket:',
              roomId: params.roomId,
              setName: claimInfo?.allowDamage ? 'Damage id' : 'Image Set Id',
              totalTimeSeconds: elapsedTime,
              template: 'roomSubmitted',
            },
          });
          log.hosta({
            event: eventNames.ROOM_CAPTURE_COMPLETED,
            data: {
              additionalPhotos: uploaded
                .filter((img) => img?.type?.includes('additional'))
                .map((img) => ({
                  [img?.type?.replace('additional-', '')]: img.key.replace(
                    '.jpeg',
                    ''
                  ),
                })),
              overviewPhotos: uploaded
                .filter((img) => img?.imgType === 'overviewImage')
                .map((img) => ({ [img.id]: img?.key?.replace('.jpeg', '') })),
              damagePhotos: uploaded
                .filter((img) => img?.type?.includes('damage'))
                .map((img) => ({
                  [img?.type?.replace('damage-', '')]: img.key.replace(
                    '.jpeg',
                    ''
                  ),
                })),
            },
          });
          setCaptureId('');
          localStorage.removeItem('cachedRoomId');

          if (flagsmith.hasFeature('notify_parent_app')) {
            sendMessagesToParentApp(uploaded);
          }
        } catch (e) {
          setError('Unable to Submit Image(s)');
          setLoad(false);
          Sentry.captureException(e);
        }
      } else {
        setError(
          `Unable to upload image(s) ${failed.map((img) => img.id).join(',')}, please try submitting again!`
        );
        setLoad(false);
        log.error({
          event: eventNames.SUBMIT_ROOM_FAIL,
          ignoreSlack: false,
          data: {
            failed,
            uploaded,
            header: 'Failed to upload images',
            roomId: params.roomId,
            template: 'roomSubmittedError',
            error: 'Failed to upload images',
          },
        });
      }
    })();
  };

  const sendMessagesToParentApp = (upload: any[]) => {
    const message = JSON.stringify({ success: 'Room Submitted', upload });
    if (
      (window as any).webkit &&
      (window as any).webkit.messageHandlers &&
      (window as any).webkit.messageHandlers.iosListener
    ) {
      (window as any).webkit.messageHandlers.iosListener.postMessage(message);
    }
    if ((window as any).Android && (window as any).Android?.logData) {
      (window as any).Android.logData(message);
    }
    if (window.parent) {
      window.parent.postMessage(message, '*');
    }
  };

  if (!pictures) return <></>;

  const handleOpen = () => {
    onNextButtonClick();
  };

  const handleCloseErrorModal = () => {
    handleOpen();
    setError(null);
    onNextButtonClick();
  };

  const handleBadDBErrorModal = () => {
    setBadDBError(null);
    navigate(`/${params.captureType}/${params.claimId}/room-select`);
  };

  return (
    <>
      <PhotosLoading open={load} roomId={params.roomId} />
      {error && (
        <Modal
          noCloseHandlers={!navigator.onLine}
          handleClose={handleCloseErrorModal}
          handleDone={handleCloseErrorModal}
          buttonText="Got it"
          title="Something went wrong!"
          text={error}
        />
      )}
      {badDBError && (
        <Modal
          noCloseHandlers
          handleClose={handleBadDBErrorModal}
          handleDone={handleBadDBErrorModal}
          buttonText="Restart Capture"
          title="Something went wrong!"
          text={badDBError}
        />
      )}
    </>
  );
};

export default ConfirmPhotosPage;
