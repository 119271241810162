import classnames from 'classnames';
import React from 'react';
import { useEntityConfigStore } from 'src/stores/entityConfig';

import ButtonImage from 'src/components/ButtonImage/ButtonImage';
import Footer from 'src/components/Footer/Footer';

type Props = {
  handleYes: () => void;
  handleNo: () => void;
  bordered?: boolean;
};

const YesNoButtons = ({ handleNo, handleYes, bordered }: Props) => {
  const captureImageCopy = useEntityConfigStore(
    (state) => state.copy.captureImage
  );
  return (
    <Footer extraClassname={classnames({ 'bg-white pt-4 border-t': bordered })}>
      <div className="flex gap-5">
        <ButtonImage
          outlined
          textIcon="YES"
          testId="small-room-looks-good-btn"
          textIconColor="text-[#53B763]"
          onClick={handleYes}
          flexDirection="col"
          textAlign="center"
          icon="/icons/green-check.svg"
          label={captureImageCopy.retake.yesBtn}
          className="text-xs border-gray-300 bg-white font-medium"
        />
        <ButtonImage
          outlined
          textIcon="NO"
          textIconColor="text-[#FF4057]"
          onClick={handleNo}
          flexDirection="col"
          textAlign="center"
          icon="/icons/red-x.svg"
          label={captureImageCopy.retake.noBtn}
          className="text-xs border-gray-300 bg-white font-medium"
        />
      </div>
    </Footer>
  );
};

export default YesNoButtons;
