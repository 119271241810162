import classnames from 'classnames';
import { useEntityConfigStore } from 'src/stores/entityConfig';

type Props = {
  imageNumber: number;
};

const CaptureDescription = ({ imageNumber }: Props) => {
  const captureImageCopy = useEntityConfigStore(
    (state) => state.copy.captureImage
  );
  const isFirstCapture = imageNumber === 1;
  return (
    <div className="py-8">
      {isFirstCapture ? (
        <p>{captureImageCopy.firstCapture.imageHeading}</p>
      ) : null}
      <img
        alt={isFirstCapture ? 'First Wall' : 'Next Wall'}
        src={isFirstCapture ? '/images/Row.png' : '/images/Next.png'}
        className={classnames('w-full', {
          'py-2': isFirstCapture,
        })}
      />
    </div>
  );
};

export default CaptureDescription;
