// Types
import type { ReactElement } from 'react';

import Text from 'src/components/Text/Text';

type Props = {
  icon: string;
  title: string;
  testId?: string;
  subtitle: string;
  onClick: () => void;
};

const RetakeButton = ({
  icon,
  title,
  subtitle,
  onClick,
  testId = 'retake-btn',
}: Props): ReactElement => {
  return (
    <div
      data-testid={testId}
      className="w-full p-6 border rounded-lg border-[#CDD5E0] hover:border-[#1D66CF] hover:bg-[#F5F9FF] cursor-pointer"
      onClick={onClick}
    >
      <div className="flex w-full items-center justify-center pb-2">
        <img
          data-testid="retake-btn-icon"
          alt="icon"
          className="mr-2"
          src={icon}
        />
        <Text
          testId="retake-btn-title"
          variant="subtitle"
          extraClassname="text-[#1D66CF]"
          weight="medium"
        >
          {title}
        </Text>
      </div>
      <Text
        testId="retake-btn-subtitle"
        variant="paragraph"
        extraClassname="text-sm"
        weight="medium"
      >
        {subtitle}
      </Text>
    </div>
  );
};

export default RetakeButton;
