import CheckIcon from '@mui/icons-material/Check';
import CircleIcon from '@mui/icons-material/Circle';
import CloseIcon from '@mui/icons-material/Close';
import ReduceIcon from '@mui/icons-material/CloseFullscreen';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import ExpandIcon from '@mui/icons-material/OpenInFull';
import RedoIcon from '@mui/icons-material/Restore';
import AppBar from '@mui/material/AppBar';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { TransitionProps } from '@mui/material/transitions';
import classnames from 'classnames';
import mixpanel from 'mixpanel-browser';
import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Webcam from 'react-webcam';
import { useFeatureStore } from 'src/stores/featureStore';

import * as log from 'src/utils/logger';
import { convertInputImageToBase64 } from 'src/utils/utils';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface WebCamProps {
  handleClose: () => void;
  handleUpload: (data: any) => void;
}

interface IBaseButtonProps {
  className?: string;
  label?: string;
  disabled?: boolean;
  loading?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onCapture?: (image: string, file?: File) => void;
  outlined?: boolean;
  icon?: string | React.ReactElement;
  type?: 'button' | 'submit' | 'capture' | 'nativeCapture';
  name?: string;
  lightBg?: boolean;
  contained?: boolean;
  weight?: 'medium' | 'bold' | 'normal';
  dataTestId?: string;
  capFirstLetterOnly?: boolean;
}

const BaseButton = (props: IBaseButtonProps) => {
  const {
    className,
    label,
    disabled,
    loading,
    onClick,
    outlined,
    icon,
    type,
    name,
    lightBg,
    onCapture,
    contained,
    weight = 'bold',
    dataTestId = 'base-button',
    capFirstLetterOnly = false,
  } = props;
  const { claimId, roomId } = useParams();
  const uploadRef = useRef(null);
  const [processing, setProcessing] = useState(false);
  const [failed, setFailed] = useState(false);
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onClick?.(event);
    if (name) {
      mixpanel.track(name, { claimId, roomId });
    }
  };
  const [open, setOpen] = useState<boolean>(false);

  const inBrowserCapture = useFeatureStore((state) => state.inBrowserCapture);

  const cx = classnames(className || '', 'rounded-lg w-full h-12', {
    'border-2 border-primary text-primary': outlined && !disabled && !contained,
    'border-2 border-base-btn-color-disabled text-base-btn-color-disabled cursor-not-allowed':
      outlined && disabled && !contained,
    'bg-base-btn-color-primary text-white':
      !outlined && !disabled && !contained,
    'bg-base-btn-color-disabled text-white cursor-not-allowed':
      disabled && !contained && !outlined,
    'bg-base-btn-color-secondary':
      lightBg && !disabled && !outlined && !contained,
    'font-medium': weight === 'medium',
    'font-bold': weight === 'bold',
  });

  // const imageWorker: Worker = useMemo(() => new Worker(new URL('../../workers/image.ts', import.meta.url)), []);

  const handleUpload = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    evt.preventDefault();
    evt.stopPropagation();
    if (evt.target.files && evt.target.files.length > 0) {
      try {
        log.info({
          event: 'Image Captured',
          data: {
            name,
            captureType:
              window.screen.width > window.screen.height
                ? 'Landscape'
                : 'Portrait',
          },
        });
        setProcessing(true);
        const file = evt.target.files[0];
        if (!file.type.startsWith('image/')) {
          alert('Please upload proper image file');
          setProcessing(false);
          setFailed(true);
          return;
        }
        const image = await convertInputImageToBase64(file);
        setProcessing(false);
        onCapture?.(image);
        setFailed(false);
      } catch (ex) {
        console.log(ex);
        setFailed(true);
      }
    }
  };

  const handleImageCapture = async (data: string) => {
    onCapture?.(data);
  };

  const handleButtonClick = (
    evt: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    evt.preventDefault();
    evt.stopPropagation();
    if (!loading && !disabled) {
      if (type === 'capture' && uploadRef.current) {
        if (!inBrowserCapture) {
          (uploadRef.current as HTMLInputElement).click();
        } else {
          setOpen(true);
        }
      } else {
        handleClick(evt);
      }
    }
  };

  const getIcon = () => {
    if (processing) {
      return '/icons/Image-Processing.gif';
    }
    if (failed) {
      return '/icons/x@2x.png';
    }
    if (icon && typeof icon === 'string') {
      return icon;
    }
    if (type === 'capture') {
      return '/icons/Retake photo@2x.png';
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {type === 'capture' && (
        <input
          hidden
          accept="image/*"
          onChange={handleUpload}
          ref={uploadRef}
          type="file"
          data-testid={dataTestId + '-input'}
          capture="environment"
          name="Capture-Image"
        />
      )}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <WebCamComponent
          handleClose={handleClose}
          handleUpload={handleImageCapture}
        />
      </Dialog>
      <button
        data-testid={dataTestId}
        type={type === 'submit' ? 'submit' : 'button'}
        disabled={loading || disabled || processing}
        className={cx}
        onClick={handleButtonClick}
      >
        {loading ? (
          <div
            className="loader"
            id="base-button-loader"
            data-testid="base-button-loader"
          />
        ) : (
          <div className="flex flex-row justify-center items-center">
            {icon && typeof icon === 'string' && (
              <img
                className={`pr-3 ${processing ? 'h-12' : 'h-6'}`}
                alt="icon"
                src={getIcon()}
              />
            )}
            {icon && typeof icon !== 'string' && (
              <div className="mr-1">{icon}</div>
            )}
            {!processing && (
              <span
                className={classnames({
                  capitalize: !capFirstLetterOnly,
                  'first-letter:capitalize': capFirstLetterOnly,
                })}
              >
                {label}
              </span>
            )}
          </div>
        )}
      </button>
    </>
  );
};

const WebCamComponent = (props: WebCamProps) => {
  const { handleClose, handleUpload } = props;
  const [img, setImg] = useState<string | null>(null);
  const ref = useRef(null);

  const [zoom, setZoom] = useState(false);
  const [flip, setFlip] = useState(false);

  const handlePhoto = () => {
    let image = (ref.current as any)?.getScreenshot();
    setImg(image);
  };

  const videoConstraints: any = {
    facingMode: flip ? 'user' : 'environment',
    zoom: zoom ? 0.5 : 1,
  };

  return (
    <>
      <AppBar color="default" sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Capture Image
          </Typography>
        </Toolbar>
      </AppBar>
      <Box className="w-full h-full bg-black">
        <div className="flex w-full h-full items-center justify-center">
          {img && <img src={img} alt="Captured" />}
          {!img && (
            <Webcam
              audio={false}
              videoConstraints={videoConstraints}
              ref={ref}
            />
          )}
        </div>
        <Paper
          sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
          elevation={3}
        >
          <BottomNavigation showLabels={false}>
            {!img && (
              <BottomNavigationAction
                onClick={() => setZoom(!zoom)}
                label="Zoom"
                icon={zoom ? <ExpandIcon /> : <ReduceIcon />}
              />
            )}
            {!img && (
              <BottomNavigationAction
                onClick={handlePhoto}
                label="Capture"
                icon={<CircleIcon />}
              />
            )}
            {!img && (
              <BottomNavigationAction
                onClick={() => setFlip(!flip)}
                label="Close"
                icon={<FlipCameraAndroidIcon />}
              />
            )}
            {img && (
              <BottomNavigationAction
                onClick={() => {
                  handleUpload(img);
                  handleClose();
                }}
                label="Save"
                icon={<CheckIcon />}
              />
            )}
            {img && (
              <BottomNavigationAction
                onClick={() => setImg(null)}
                label="Retake"
                icon={<RedoIcon />}
              />
            )}
          </BottomNavigation>
        </Paper>
      </Box>
    </>
  );
};

export default BaseButton;
