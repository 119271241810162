import { getImageLink } from 'src/REST/capture';
import { useClaimInfoStore } from 'src/stores/claimInfo';

import { dexieDb } from 'src/utils/indexedDb';

import { base64ToFile } from 'src/utils/utils';

type UploaderParams = {
  imgId: number;
  roomId: string;
  claimId: string;
  content?: string;
  abortController?: AbortController;
  recaptureKey?: string | undefined;
  imageField?: string;
  imageType?: string;
  damageId?: any;
};

const getBase64StringFromDataURL = (dataURL: string) =>
  dataURL.replace('data:', '').replace(/^.+,/, '');

const imageUploader = async ({
  imgId,
  claimId,
  roomId,
  content = '',
  recaptureKey,
  imageField,
  imageType,
  damageId = {},
}: UploaderParams) => {
  const file = base64ToFile(getBase64StringFromDataURL(content));
  return await getImageLink(
    claimId,
    roomId,
    file,
    'jpeg',
    imgId.toString(),
    imageField,
    imageType,
    damageId,
    [],
    undefined,
    recaptureKey
  );
};

export const uploadRoomImage = async ({
  imgId,
  claimId,
  roomId,
  abortController,
  recaptureKey,
}: UploaderParams) => {
  const img = await dexieDb.pictures.get(imgId);
  if (img && !img.uploaded && img.uploadId) {
    let extraImage;
    const allowDamage = useClaimInfoStore.getState()?.allowDamage;
    const imageField = allowDamage ? 'damageImages' : 'additionalImages';
    const imageType = allowDamage
      ? img.imgType
      : img.imgType?.replace('damage', 'additional');
    const imageSet = allowDamage ? 'damageId' : 'imageSetId';
    const isOverviewImg = img.imgType === 'overviewImage';

    const image = (await imageUploader({
      claimId,
      roomId,
      imgId: img.uploadId,
      content: img.content,
      abortController,
      recaptureKey,
      imageField: isOverviewImg ? 'overviewImages' : imageField,
      damageId: isOverviewImg ? {} : { [imageSet]: img.damageId },
      ...(!isOverviewImg && { imageType }),
    })) as any;
    if (img.extraImages && img.extraImages.length > 0) {
      extraImage = (await imageUploader({
        claimId,
        roomId,
        imgId: img.uploadId + 1,
        content: img.extraImages[0],
        imageField: 'overviewImages',
        recaptureKey,
      })) as any;
    }

    await dexieDb.pictures.update(img.id, {
      uploaded: true,
      awsKey: image?.key,
      ...(extraImage && { extraAWSKey: extraImage?.key }),
    });

    return {
      image,
      extraImage,
    };
  }

  return {
    image: img,
    extraImage: img?.extraImages?.[0],
  };
};
