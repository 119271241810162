import type { Address } from 'src/stores/claimInfo';

type Props = {
  claimId?: string;
  address?: Address | null;
};

const ClaimInfo = ({ claimId, address }: Props) => {
  const getFormattedAddress = (address: Address) => {
    if (address) {
      const params = ['address1', 'address2', 'city', 'state', 'zip'];
      return params
        .map((e) => address[e as keyof Address])
        .filter((e) => e && e.trim() !== '')
        .join(', ');
    }
    return '';
  };

  return (
    <div className="flex flex-col w-full" data-testid="claim-info">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
          <div className="overflow-hidden border rounded-lg">
            <table className="min-w-full text-center text-sm font-light">
              <tbody>
                {claimId && (
                  <tr className="border-b" data-testid="claim-info-claim-id">
                    <td className="min-w-[100px] border-b border-r bg-neutral-50 px-4 font-medium dark:text-neutral-800">
                      Claim ID
                    </td>
                    <td className="px-6 py-4 break-words max-w-[200px] bg-white">
                      {claimId}
                    </td>
                  </tr>
                )}
                <tr
                  className="dark:border-neutral-500"
                  data-testid="claim-info-address"
                >
                  <td className="min-w-[100px] border-r bg-neutral-50 px-4 font-medium dark:text-neutral-800">
                    Address
                  </td>
                  <td className="px-6 py-4 break-words max-w-[200px] bg-white">
                    {address ? getFormattedAddress(address) : null}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClaimInfo;
