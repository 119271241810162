import DialogContent from '@mui/material/DialogContent';
import React from 'react';

import AppHeader from 'src/components/Header/RecaptureHeader';
import Text from 'src/components/Text/Text';

import ImageSlider from '../slider';

interface CategorySelectionProps {
  handleClose: () => void;
  handleChange: (newIndex: number) => void;
  data: {
    photos?: string[];
  };
  active: number;
  categories: { type: string; icon: string }[];
  handleSelect: (type: string) => void;
}

const CategorySelection: React.FC<CategorySelectionProps> = ({
  handleClose,
  handleChange,
  data,
  active,
  categories,
  handleSelect,
}) => {
  return (
    <>
      <AppHeader label="Select a Room Category" onClose={handleClose} />
      <DialogContent>
        <div className="max-w-[500px] m-auto">
          <div className="flex flex-col gap-2">
            <Text align="left" variant="paragraph">
              Tell us what category this room is and we'll show you tips on how
              to retake this photo.
            </Text>
            <ImageSlider
              onChange={handleChange}
              images={[data?.photos?.[active]] || []}
            />
          </div>
          <Text
            extraClassname="font-semibold"
            align="center"
            variant="paragraph"
          >
            Room Categories
          </Text>
          <div className="h-8" />
          <div className="flex gap-4 flex-wrap justify-center">
            {categories.map((x, idx) => (
              <div
                key={idx}
                onClick={() => {
                  handleSelect(x.type);
                }}
                className="border border-2 rounded-lg min-w-[70px] p-2 flex items-center flex-col cursor-pointer"
              >
                <img src={x.icon} alt={x.type} />
                <small className="capitalize text-blue-700 text-center">
                  {x.type.split('-').join(' ')}
                </small>
              </div>
            ))}
          </div>
        </div>
      </DialogContent>
    </>
  );
};

export default CategorySelection;
