export const eventNames = {
  START_PROCESS: 'Start Process',
  SELECT_ROOM: 'Select Room',
  ROOM_SELECTED_SUCCESS: 'Room Type Selection Success',
  ROOM_SELECTED_FAIL: 'Room Type Selection Error',
  START_DAMAGE: 'Start Damage Capture',
  CLOSE_UP_PHOTO: 'Close Up Photo',
  CONTEXT_PHOTO: 'Context Photo',
  RETAKE_PHOTO: 'Retake Photo',
  ADD_MORE_DAMAGE: 'Add More Damage',
  DONE_WITH_DAMAGE: 'Done With Damage',
  START_ROOM: 'Start Room Capture',
  BACK: 'Clicked on Back',
  RESTART: 'Clicked on Restart',
  NEXT_TIP: 'Next Tip',
  SKIP_TIPS: 'Skip Tips',
  TAKE_WALL_PHOTO: 'Take Wall Photo',
  CONFIRM_GOOD_PHOTO: 'Confirm Good Photo',
  CONFIRM_BAD_PHOTO: 'Confirm Bad Photo',
  RETAKE_ONE_PHOTO: 'Retake With 1 Photo',
  RETAKE_TWO_PHOTO: 'Retake With 2 Photos',
  COMPLETE_ROOM_CAPTURE: 'Complete Room Capture',
  ADD_ANOTHER_ROOM: 'Add Another Room',
  DONE_WITH_ROOM: 'Done with Room',
  SUBMIT_ROOM_SUCCESS: 'Room Submitted',
  SUBMIT_ROOM_FAIL: 'Failed to Upload',
  COMPLETE_ROOM: 'Complete Process',
  SURVEY_FEEDBACK: 'Survey Feedback',
  RETURN_TO_URL: 'Return to URL',
  ROOM_DESCRIPTIONS: 'Room Descriptions',

  // Layout Capture
  OPEN_LAYOUT_CAPTURE: 'Open Layout Capture',
  CAPTURE_ERROR: 'Finish Layout Capture',
  CHANGE_CAPTURE_LAYOUT: 'Change Capture Layout',
  WIDE_ANGLE_CAMERA: 'Wide Angle Camera',
  ROOM_REVIEW: 'Room Review',
  CAPTURE_COMPLETE: 'Capture Complete',
  ZOOM_NOT_SUPPORTED: 'Zoom Not Supported',
  ERROR_ACCESSING_CAMERA_CAPABILITIES: 'Error Accessing Camera Capabilities',
  TWO_PHOTO_LAYOUT_FROM_SUGGESTION: 'Two Photo Layout From Suggestion',
  DISMISS_TWO_PHOTO_LAYOUT_SUGGESTION: 'Dismiss Two Photo Layout Suggestion',
  VIDEO_CONSTRAINS_APPLIED: 'Video Constrains Applied',
  VIDEO_CONSTRAINS_ERROR: 'Video Constrains Error',

  // New hosta events
  CAPTURE_STARTED: 'CAPTURE_STARTED',
  ROOM_TYPE_SELECTED: 'ROOM_TYPE_SELECTED',
  ROOM_NAME_ENTERED: 'ROOM_NAME_ENTERED',
  ROOM_NAME_SKIPPED: 'ROOM_NAME_SKIPPED',
  DAMAGE_DESCRIPTION_ENTERED: 'DAMAGE_DESCRIPTION_ENTERED',
  DAMAGE_DESCRIPTION_SKIPPED: 'DAMAGE_DESCRIPTION_SKIPPED',
  DAMAGE_CLOSE_UP_CAPTURED: 'DAMAGE_CLOSE_UP_CAPTURED',
  DAMAGE_CONTEXT_CAPTURED: 'DAMAGE_CONTEXT_CAPTURED',
  ROOM_PHOTO_CAPTURED: 'ROOM_PHOTO_CAPTURED',
  IMAGE_UPLOAD_STARTED: 'IMAGE_UPLOAD_STARTED',
  ROOM_CAPTURE_COMPLETED: 'ROOM_CAPTURE_COMPLETED',
  CLAIM_CAPTURE_COMPLETED: 'CLAIM_CAPTURE_COMPLETED',
  FLOOR_AND_CEILING_CONFIRMED: 'FLOOR_AND_CEILING_CONFIRMED',
};
