import EastIcon from '@mui/icons-material/East';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import WestIcon from '@mui/icons-material/West';

type Props = {
  directions: string[];
  image: string;
  errors: string[];
};

const MissingEdges = ({ directions, errors, image }: Props) => (
  <>
    {errors.map((e) => (
      <p key={e}>{e}</p>
    ))}
    <div className="relative">
      {directions.includes('top') && (
        <div className="absolute top-0 inset-x-0 flex flex-row justify-center mt-4">
          <small className="bg-white rounded-lg p-1 text-xs font-semibold">
            Top edge is missing
            <NorthIcon fontSize="small" />
          </small>
        </div>
      )}
      {directions.includes('bottom') && (
        <div className="absolute bottom-0 inset-x-0 flex flex-row justify-center mb-4">
          <small className="bg-white rounded-lg p-1 text-xs font-semibold">
            Bottom edge is missing
            <SouthIcon fontSize="small" />
          </small>
        </div>
      )}
      {directions.includes('left') && (
        <div className="absolute left-0 inset-y-0 flex flex-col justify-center ml-2">
          <small className="bg-white rounded-lg p-1 text-xs font-semibold">
            <WestIcon fontSize="small" />
            Left edge is missing{' '}
          </small>
        </div>
      )}
      {directions.includes('right') && (
        <div className="absolute right-0 inset-y-0 flex flex-col justify-center mr-2">
          <small className="bg-white rounded-lg p-1 text-xs font-semibold">
            Right edge is missing
            <EastIcon fontSize="small" />
          </small>
        </div>
      )}
      <img alt="First Wall" src={image} className="w-full py-2" />
    </div>
  </>
);

export default MissingEdges;
