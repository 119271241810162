import classnames from 'classnames';

type Props = {
  logo: string;
  logoVerified: boolean;
  hideHostaBranding: boolean;
  extraClassName?: string;
};

const BrandingHeader = ({
  logo,
  logoVerified,
  hideHostaBranding,
  extraClassName,
}: Props) => {
  return (
    <div
      className={`flex w-full items-center justify-center pb-12 ${extraClassName || ''}`}
    >
      {logoVerified && (
        <div
          id="company-logo"
          className={classnames('pr-2', {
            'border-r': !hideHostaBranding,
          })}
        >
          <img
            alt="Hosta Logo"
            className={classnames({
              'w-[64px]': !hideHostaBranding,
              'w-[200px]': hideHostaBranding,
            })}
            src={logo || ''}
          />
        </div>
      )}
      {!hideHostaBranding && (
        <div className={classnames({ 'pl-2': logoVerified })}>
          <img
            alt="Hosta Logo"
            className={classnames({
              'w-[64px]': logoVerified,
              'w-[128px]': !logoVerified,
            })}
            src="/images/damage/Hosta Logo.png"
          />
        </div>
      )}
    </div>
  );
};

export default BrandingHeader;
