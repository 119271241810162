import React, { ReactNode } from 'react';

export interface IInstructionTitleProps {
  text?: string;
  subtitleText?: string;
  textRight?: ReactNode;
  className?: string;
}

const InstructionTitle: React.FC<IInstructionTitleProps> = (props) => {
  const { text, subtitleText, textRight, className } = props;
  const splitText = text?.split(' ');
  const lastTextWord = splitText?.pop();
  const parsedText = splitText?.join(' ');

  return (
    <div className="w-full">
      {textRight ? (
        <h1
          className={`text-xl whitespace-pre-line font-bold text-darkGrey desktops:text-5xl desktops:mb-4 mb-2 ${className}`}
        >
          {parsedText}
          &nbsp;
          <span>
            {lastTextWord} {textRight}
          </span>
        </h1>
      ) : (
        <h1
          className={`text-xl whitespace-pre-line font-bold text-darkGrey desktops:text-5xl desktops:mb-4 mb-2 ${className}`}
        >
          {text}
        </h1>
      )}
      {subtitleText ? (
        <p className={`text-[16px] text-lightGrey ${className}`}>
          <small>{subtitleText}</small>
        </p>
      ) : null}
    </div>
  );
};

export default InstructionTitle;
