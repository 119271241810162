import * as Sentry from '@sentry/react';
import Dexie, { Table } from 'dexie';

export interface IRecapture {
  id: number;
  content: string;
  roomId?: string;
  lastUpdated?: number;
}

export class MySubClassedDexie extends Dexie {
  recapture!: Table<IRecapture>;

  constructor() {
    super('recapture-database-v1');
    this.version(1).stores({
      recapture: `++id, roomId`,
    });
  }
}

export const handleError = (error: any): void => {
  switch (error.name) {
    case 'AbortError':
      Sentry.addBreadcrumb({ message: 'AbortError', level: 'error' });
      if (error.inner) {
        return handleError(error.inner);
      }
      Sentry.captureException(error);
      break;
    case 'QuotaExceededError':
      Sentry.addBreadcrumb({ message: 'QuotaExceededError', level: 'error' });
      Sentry.captureException(error);
      break;
    default:
      Sentry.captureException(error);
      break;
  }
};

export const validateIndexedDBFields = (data: any) => {
  // Define the required fields
  const requiredFields = ['id', 'content', 'roomId', 'lastUpdated'];

  // Check if all required fields are present in each object
  return data.every((item: IRecapture) =>
    requiredFields.every((field) => item.hasOwnProperty(field))
  );
};

export const dexieDb = new MySubClassedDexie();
