// init sentry
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@fontsource-variable/work-sans';
import 'plyr/dist/plyr.css';
import React, { useEffect } from 'react';
import 'react-calendar/dist/Calendar.css';
import ReactDOM from 'react-dom/client';
import { HashRouter, useLocation } from 'react-router-dom';

import './styles/index.css';

import 'src/utils/sentry';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// https://crockford.com/javascript/remedial.html
type GenericObject = {
  [key: string]: any;
};

declare global {
  interface String {
    supplant(obj: GenericObject): string;
  }
}

// eslint-disable-next-line no-extend-native
Object.defineProperty(String.prototype, 'supplant', {
  value(o: GenericObject) {
    return this.replace(/{([^{}]*)}/g, function (a: any, b: any) {
      const r = o[b];
      return typeof r === 'string' || typeof r === 'number' ? r : a;
    });
  },
});

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    try {
      document.getElementsByClassName('damage-content')[0].scrollTo({
        top: 0,
        left: 0,
      });
    } catch (ex) {}
  }, [pathname]);

  return null;
};

root.render(
  // <React.StrictMode>
  <HashRouter>
    <ScrollToTop />
    <App />
  </HashRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

window.addEventListener('load', function () {
  if (
    sessionStorage.getItem('good_exit') &&
    sessionStorage.getItem('good_exit') !== 'true'
  ) {
    sessionStorage.setItem('did_crash', 'true');
  }
  sessionStorage.setItem('good_exit', 'pending');
});

window.addEventListener('beforeunload', function () {
  sessionStorage.setItem('good_exit', 'true');
});

// Example registration code
if ('serviceWorker' in navigator) {
  window.addEventListener('load', function () {
    navigator.serviceWorker
      .register('/sw.js')
      .then(
        function (registration) {
          registration.onupdatefound = () => {
            const installingWorker = registration.installing;
            if (installingWorker == null) {
              return;
            }
            installingWorker.onstatechange = () => {
              if (installingWorker.state === 'installed') {
                if (navigator.serviceWorker.controller) {
                  // At this point, the updated precached content has been fetched,
                  // but the previous service worker will still serve the older
                  // content until all client tabs are closed.
                  console.log('New content is available and will be used');
                  const waitingServiceWorker = registration.waiting;
                  if (waitingServiceWorker) {
                    waitingServiceWorker.addEventListener(
                      'statechange',
                      (event) => {
                        // @ts-ignore
                        if (event.target.state === 'activated') {
                          window.location.reload();
                        }
                      }
                    );
                    waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
                  }
                }
              }
            };
          };
        },
        function (err) {
          console.log('Service Worker registration failed:', err);
        }
      )
      .catch((error) => {
        console.error('Error during service worker registration:', error);
      });
  });
}
