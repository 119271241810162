import RetakePhoto from './RetakePhoto';
import YesNoButtons from './YesNoButtons';
import React, { useState } from 'react';

import ContentView from 'src/components/Content/Content';
import DamageHeader from 'src/components/Header/DamageHeader';
import Text from 'src/components/Text/Text';

import numberToOrdinalWord from 'src/utils/numberToOrdinalWord';

type Props = {
  imageNumber: number;
  imageData: string;
  onComplete: () => void;
  handleCancel?: () => void;
};

const SmallRoomCapture = ({
  imageNumber,
  imageData,
  onComplete,
  handleCancel,
}: Props) => {
  const [show, setShow] = useState(false);

  if (show) {
    return (
      <RetakePhoto
        imageNumber={imageNumber}
        onComplete={onComplete}
        handleBack={() => setShow(false)}
      />
    );
  }

  return (
    <div className="w-full" id="small-room-review">
      <DamageHeader handleBack={() => handleCancel?.()} />
      <ContentView className="pb-48">
        <Text variant="title">Can you see every edge ?</Text>
        <div className="py-2">
          <p>{numberToOrdinalWord(imageNumber)} wall</p>
          <img alt="First Wall" src={imageData} className="w-full py-2" />
        </div>
        <YesNoButtons
          bordered
          handleYes={onComplete}
          handleNo={() => setShow(true)}
        />
      </ContentView>
    </div>
  );
};

export default SmallRoomCapture;
