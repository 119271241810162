import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Plyr from 'plyr';
import React, { useRef, useEffect, useCallback } from 'react';
import { useEntityConfigStore } from 'src/stores/entityConfig';
import { analytics } from 'src/texts/texts';

import BaseButton from 'src/components/BaseButton/BaseButton';
import ContentView from 'src/components/Content/Content';
import Footer from 'src/components/Footer/Footer';
import Text from 'src/components/Text/Text';

import * as log from 'src/utils/logger';

type Props = {
  isOpen: boolean;
  handleAction: () => void;
  actionLabel: string;
  onClose?: () => void;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InstructionalVideo = ({
  isOpen,
  actionLabel,
  handleAction,
  onClose,
}: Props) => {
  const plyrRef = useRef<Plyr | null>(null);
  const entityConfig = useEntityConfigStore((state) => state.config);

  const unmountPlyr = useCallback(() => {
    plyrRef.current?.off('play', onPlay);
    plyrRef.current?.off('ended', onEnd);
    plyrRef.current?.destroy();
  }, []);

  useEffect(() => {
    return () => {
      unmountPlyr();
    };
  }, [unmountPlyr]);

  const onPlay = () => {
    log.info({
      event: analytics.instructionalVideoPlayStarted,
      data: {},
    });
  };

  const onEnd = () => {
    log.success({
      event: analytics.instructionalVideoComplete,
      data: {},
    });
  };

  const mountPlyr = () => {
    plyrRef.current = new Plyr('#player', {
      settings: [],
    });

    plyrRef.current.on('play', onPlay);
    plyrRef.current.on('ended', onEnd);
  };

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={onClose}
      TransitionComponent={Transition}
      TransitionProps={{
        onEntered: mountPlyr,
        onExit: unmountPlyr,
      }}
    >
      <ContentView className="gap-2 -mt-14 portrait:min-h-screen landscape:pb-36">
        <>
          <div className="flex flex-col w-full pt-14">
            <div className="w-full desktops:w-desktop px-4 desktops:px-0 max-w-desktop">
              <div className="pb-3">
                <Text variant="title">See how Hosta AI works</Text>
              </div>
              <Text variant="subtitle">
                Get a quick overview of how to best document the damage in your
                home using Hosta AI.
              </Text>
            </div>

            <div className="w-full mt-10">
              <video id="player" playsInline controls width="100%">
                <source
                  src={`${process.env.REACT_APP_CLOUDFRONT_ASSETS_URL}/capture-instructions.mp4`}
                  type="video/mp4"
                />
              </video>
            </div>
            <Text variant="paragraph" align="left" extraClassname="mt-10 pl-5">
              • Plan 5 minutes or less per room.
            </Text>
            <Text variant="paragraph" align="left" extraClassname="mt-2 pl-5">
              • Start and stop at your convenience.
            </Text>
            <Text variant="paragraph" align="left" extraClassname="mt-2 pl-5">
              • Photos are auto-saved.
            </Text>
          </div>

          <Footer>
            <BaseButton
              dataTestId="instructional-video-action"
              name={analytics.instructionalVideoAction}
              onClick={handleAction}
              label={actionLabel}
              capFirstLetterOnly={entityConfig.capFirstLetterOnly}
            />
          </Footer>
        </>
      </ContentView>
    </Dialog>
  );
};

export default InstructionalVideo;
