import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import React, { useEffect, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';

import HelpContext from 'src/components/HelpModal';
import Text from 'src/components/Text/Text';

interface RightPhotoProps {
  roomType: {
    left: string;
    right: string;
  };
  data: {
    photos?: { [key: string]: { left?: string } };
  };
  active: number;
  handleRightPhoto: () => void;
  handleLeftPhoto: () => void;
  handleSingle: () => void;
}

const RightPhoto: React.FC<RightPhotoProps> = ({
  roomType,
  data,
  active,
  handleRightPhoto,
  handleLeftPhoto,
  handleSingle,
}) => {
  const [showTip1, setShowTip1] = useState(false);
  const [showTip2, setShowTip2] = useState(false);
  const [tipsCount, setTipsCount] = useLocalStorage<number>('tipsCount', 0);

  useEffect(() => {
    hideTips();
  }, [data]);

  const hideTips = () => {
    setShowTip1(false);
    setShowTip2(false);
  };

  const captureRight = () => {
    hideTips();
    handleRightPhoto();
  };

  const captureLeft = () => {
    hideTips();
    handleLeftPhoto();
  };

  const showTip = (tipNumber: number) => {
    if (tipsCount >= 2) {
      if (tipNumber === 1) {
        captureLeft();
      } else {
        captureRight();
      }
      return;
    }
    setTipsCount(tipsCount + 1);
    if (tipNumber === 1) {
      setShowTip1(true);
    }
    if (tipNumber === 2) {
      setShowTip2(true);
    }
  };

  return (
    <div className="flex flex-col justify-between h-full">
      <div className="flex flex-col gap-2">
        <Card>
          <CardContent>
            <div className="flex flex-col gap-2">
              <Text
                align="left"
                variant="subtitle"
                extraClassname="font-semibold"
              >
                {roomType.left}
              </Text>
              <img src={data?.photos?.[active]?.left} alt="Left" />
            </div>
          </CardContent>
        </Card>
        <Text align="center" variant="paragraph">
          Can you see corners and some of the floor and ceiling in this photo?
        </Text>
      </div>
      <div className="flex flex-col gap-2">
        <HelpContext
          open={showTip1}
          onClose={() => setShowTip1(false)}
          onSubmit={captureRight}
        />
        <HelpContext
          open={showTip2}
          onClose={() => setShowTip2(false)}
          onSubmit={captureLeft}
        />
        <Button onClick={() => showTip(2)} variant="contained">
          Yes, Take {roomType.right}
        </Button>
        <Button onClick={() => showTip(1)} variant="outlined">
          No, Retake Photo
        </Button>
        <Button onClick={handleSingle}>I Don't Need a Second Photo</Button>
      </div>
    </div>
  );
};

export default RightPhoto;
