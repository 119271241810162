import classnames from 'classnames';
import { useState, useEffect, ReactElement } from 'react';
import { useEntityConfigStore } from 'src/stores/entityConfig';

export interface IOutlineSelectionButton {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  checked?: boolean;
  className?: string;
  text: string;
  name: string;
}

const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1);

const OutlineSelectionButton = ({
  onClick,
  text,
  className,
  checked,
  name,
}: IOutlineSelectionButton) => {
  const [iconComponent, setIconComponent] = useState<ReactElement | null>(null);
  const entityConfig = useEntityConfigStore((state) => state.config);

  useEffect(() => {
    if (!iconComponent) {
      (async () => {
        const module = await import(
          `src/components/icons/rooms/${capitalize(name)}`
        );
        const AnotherComponent = module.default;
        setIconComponent(<AnotherComponent className="stroke-icons-color" />);
      })();
    }
  }, [iconComponent, name]);

  return (
    <button
      onClick={onClick}
      data-testid={`room-selection-btn-${text}`}
      className={classnames(
        className || '',
        'select-none relative hover:bg-lightBlueBackground hover:border-icons-color border flex flex-col justify-center items-center rounded-2xl w-full min-w-[80px] max-w-[282px]',
        {
          'bg-lightBlueBackground !border-icons-color': checked,
          'active:bg-lightBlueBackground border-lightGreyOutline': !checked,
        }
      )}
    >
      <div className="absolute top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center">
        <div
          data-testid="btn-icon"
          className="h-8 flex justify-center items-center mb-2"
        >
          {iconComponent}
        </div>
        <p
          className={classnames('m-0 text-icons-color font-medium', {
            capitalize: !entityConfig.capFirstLetterOnly,
            'first-letter:capitalize': entityConfig.capFirstLetterOnly,
          })}
        >
          {text.toLowerCase()}
        </p>
      </div>
    </button>
  );
};

export default OutlineSelectionButton;
