export const categories = [
  {
    type: 'high-cieiling',
    icon: '/icons/recapture/high_ceiling.svg',
    video: '/videos/tall-wall-animation.mp4',
    name: 'High Ceiling',
    content:
      'Walls with high ceilings can be tough to capture in one photo. Instead take 2 photos, one of the top half and one of the bottom half of the wall. No need to move furniture.',
    left: 'Top Photo',
    right: 'Bottom Photo',
  },
  {
    type: 'basic-room',
    icon: '/icons/recapture/basic_room.svg',
    video: '/videos/basic-2photo.mp4',
    name: 'Basic Wall',
    content: `Sometimes it's easy to just reframe the angle of how you capture an entire wall in one photo. Try standing in the middle of the room or in the corner to see if you can capture all 4 corners, including the part of the ceiling and floor.`,
    left: 'Left Photo',
    right: 'Right Photo',
  },
  {
    type: 'many-angles',
    icon: '/icons/recapture/many_angles.svg',
    video: '/videos/open-floor-concept.mp4',
    name: 'Room with many angles',
    content: `Some walls have indentations that open to a nook, alcove or open area, making it tough to capture in one photo. Instead take 2 or more photos, starting with the left side and move across the room.`,
    left: 'Left Photo',
    right: 'Right Photo',
  },
  {
    type: 'open-floor-plan',
    icon: '/icons/recapture/open_floor_plan.svg',
    video: '/videos/open-floor-concept.mp4',
    name: 'Open Floor Plan',
    content:
      'Open floor plans can be tough to capture in one photo. Instead take 2 photos, one of the left side and one of the right side. No need to move furniture.',
    left: 'Left Photo',
    right: 'Right Photo',
  },
  {
    type: 'tiny-room',
    icon: '/icons/recapture/tiny_room.svg',
    video: '/videos/tiny-room.mp4',
    name: 'Tiny Room',
    content:
      'Walls in tiny rooms can be tough to capture in one photo. Instead take 2 photos, one of the top half and one of the bottom half of the wall. No need to move furniture.',
    left: 'Upper Wall Photo',
    right: 'Lower Wall Photo',
  },
];

export const checklist = [
  'There is overlap between the photos',
  'Top and bottom corners are captured',
  'Some of the ceiling is included',
  'Some of the floor is included',
];

export const getImageUrl = (
  imageId: string,
  imageUrls: string[] | undefined
) => {
  const imageUrl = imageId.split('.')[0];
  return imageUrls?.find((url) => url.includes(imageUrl));
};
