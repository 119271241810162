import { useEffect, useRef } from 'react';

type Props = {
  svg: string | undefined | null;
  image: string;
};

const DrawingsSVG = ({ svg, image }: Props) => {
  const svgWrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (svgWrapperRef.current && image && svg && !Array.isArray(svg)) {
      const svgString = svg?.replace(
        /xlink:href="[^"]*"/,
        `xlink:href="${image}"`
      );
      if ('innerHTML' in svgWrapperRef.current) {
        svgWrapperRef.current.innerHTML = svgString;

        // Get the child <svg> element
        const svgElement = svgWrapperRef.current.querySelector('svg');
        if (svgElement) {
          svgElement.setAttribute('width', '100%');
          svgElement.setAttribute('height', '100%');
        }
      }
    }
  }, [svg, image]);

  if (!svg) return <img alt="Slide" src={image} className="w-auto h-full" />;

  return <div className="h-full w-full" ref={svgWrapperRef} />;
};

export default DrawingsSVG;
