type Props = {
  className: string;
};

const BrokenGears = ({ className }: Props) => (
  <svg
    fill="#000000"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 256 240"
    xmlSpace="preserve"
    className={className}
  >
    <path
      d="M246.125,80.551l-23.615,24.747c-4.006-2.963-8.623-5.129-13.643-6.27l5.759-34.227L246.125,80.551z M166.154,98.526
	l-7.324,23.32l-25.535-3.445c-0.591,0.817-1.102,1.654-1.713,2.471c-2.235,2.874-4.705,5.434-7.334,7.806l7.599,23.674
	l-21.637,11.31L94.59,143.157c-4.518,0.797-9.086,1.122-13.663,0.886l-9.224-35.113l-32.268,15.337v0.01l-23.664,7.56L4.5,110.191
	l20.436-15.583c-0.807-4.518-1.122-9.105-0.876-13.703L2,69.556l7.304-23.261l25.525,3.426c0.591-0.817,1.102-1.654,1.733-2.461
	c2.225-2.904,4.705-5.503,7.334-7.875L36.217,15.75L57.942,4.449l15.612,20.475c4.518-0.797,9.105-1.112,13.712-0.866L98.528,1.949
	l23.32,7.324l-3.386,25.564c0.827,0.541,1.654,1.093,2.461,1.703c2.865,2.215,5.444,4.686,7.777,7.284l23.714-7.57l11.222,21.637
	l-20.445,15.642c0.787,4.499,1.112,9.066,0.876,13.634L166.154,98.526z M89.137,65.776c-9.174-2.884-18.998,2.215-21.912,11.468
	c-2.904,9.273,2.235,19.057,11.419,21.942c9.224,2.904,19.008-2.205,21.912-11.468C103.469,78.454,98.36,68.67,89.137,65.776z
	 M237.925,193.479l12.206,9.637l-7.107,12.984l-15.366-5.296c-2.874,2.953-6.064,5.542-9.696,7.54l1.742,15.543l-14.185,4.164
	l-7.088-14.677c-1.191,0.01-2.343,0.187-3.554,0.108c-3.012-0.217-5.926-0.768-8.731-1.575l-9.706,12.275l-12.935-7.127
	l5.286-15.406c-2.953-2.884-5.542-6.074-7.54-9.706l-15.593,1.772l-4.144-14.136l14.726-7.186c-0.01-1.181-0.177-2.313-0.098-3.514
	c0.197-3.002,0.748-5.887,1.545-8.692l-12.236-9.716l7.078-12.964l15.435,5.286c2.855-2.933,6.044-5.522,9.657-7.53l-1.693-15.583
	l14.086-4.134l7.235,14.638c1.142-0.03,2.264-0.187,3.416-0.118c0.738,0.049,1.477,0.128,2.205,0.226l-4.568,24.6
	c-1.723-0.02-3.475,0.207-5.217,0.719c-9.47,2.776-14.923,12.698-12.147,22.178c2.786,9.48,12.738,14.874,22.208,12.098
	c9.411-2.756,14.815-12.659,12.029-22.148c-0.689-2.343-1.811-4.44-3.248-6.221l14.913-19.5c2.933,2.845,5.503,6.034,7.481,9.627
	l15.514-1.792L254,174.047l-14.598,7.156c0.01,1.171,0.187,2.303,0.118,3.485C239.313,187.73,238.742,190.644,237.925,193.479z
	 M41.511,210.822L9.844,175.071l39.407-15.77l11.813,3.938L41.511,210.822z M92.663,226.287l-27.628,11.764l-0.035-39.375
	L92.663,226.287z"
    />
  </svg>
);

export default BrokenGears;
