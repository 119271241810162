import React from 'react';

const MissingWall = ({ left, right }: { left: string; right: string }) => {
  return (
    <div className="text-center flex flex-col items-center justify-center min-h-[250px] gap-4">
      <small>
        It seems like we are missing the section of the room between these two
        walls.
      </small>
      <div className="flex justify-center items-center gap-4 w-full">
        <img className="w-1/3 h-auto" src={left} alt="Left Wall" />
        <img
          className="w-1/3 h-auto"
          src="/icons/recapture/missing_wall.svg"
          alt="Missing Wall"
        />
        <img className="w-1/3 h-auto" src={right} alt="Right Wall" />
      </div>
    </div>
  );
};

export default MissingWall;
