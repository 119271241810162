import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import React from 'react';

const HelpContext = ({
  open,
  onClose,
  onSubmit,
}: {
  open: boolean;
  onClose: () => void;
  onSubmit?: () => void;
}) => {
  const tips = [
    'Include as much as you can in each photo',
    'Angles and perspective are OK',
    'Pinch the screen to zoom out',
  ];
  return (
    <Dialog open={open} onClose={onClose}>
      <div className="border-4 border-blue-500">
        <DialogContent>
          <h6 className="text-center text-blue-500 text-lg">Capture Tips</h6>
          {tips.map((e) => {
            return (
              <div className="flex text-blue-500 gap-2 items-center" key={e}>
                <CheckCircleIcon /> {e}
              </div>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={onSubmit} fullWidth variant="contained">
            Got It
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default HelpContext;
