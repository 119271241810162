import CaptureWrapper from './components/CaptureWrapper/CaptureWrapper';
import DamageReview from './components/DamageReview/DamageReview';
import SkipButton from './components/SkipButton/SkipButton';
import { useLiveQuery } from 'dexie-react-hooks';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEntityConfigStore } from 'src/stores/entityConfig';
import { useFeatureStore } from 'src/stores/featureStore';
import { v4 as uuid } from 'uuid';

import { dexieDb } from 'src/utils/indexedDb';

import ContentView from 'src/components/Content/Content';
import DamageHeader from 'src/components/Header/DamageHeader';
import CloseUp from 'src/components/icons/damage/CloseUp';
import Context from 'src/components/icons/damage/Context';

import useScrollUp from 'src/hooks/useScrollUp/useScrollUp';

const DamageCapture = () => {
  const [step, setStep] = useState(0);
  const { roomId } = useParams();
  const [damageId, setDamageId] = useState<string | null>(null);
  const navigate = useNavigate();
  const allowDamage = useFeatureStore((state) => state.enableDamage);
  const damageLandingCopy = useEntityConfigStore(
    (state) => state.copy.damageLanding
  );

  const pictures =
    useLiveQuery(
      async () =>
        dexieDb.pictures
          .where('roomId')
          .equals(roomId || '')
          .and(
            (p) => p.imgType === 'damage-zoomed-in' && p.damageId === damageId
          )
          .toArray(),
      [roomId, damageId]
    ) || [];

  useScrollUp(step);

  useEffect(() => {
    setDamageId(uuid());
  }, []);

  const handleNext = () => {
    if (step === 0 || step === 1) {
      setStep(step + 1);
    } else {
      setStep(0);
      setDamageId(uuid());
    }
  };

  const handleBack = () => {
    if (step === 0) {
      navigate(-1);
    } else {
      setStep(step - 1);
    }
  };

  return (
    <>
      {pictures.length <= 0 && (
        <DamageHeader
          hideBack={pictures.length >= 1}
          handleBack={handleBack}
          hideRestart={pictures.length >= 1 || !allowDamage}
          buttonRight={
            pictures.length <= 0 && !allowDamage ? <SkipButton /> : undefined
          }
        />
      )}
      <ContentView>
        {step === 0 && damageId && (
          <CaptureWrapper
            handleNext={handleNext}
            damageId={damageId}
            imgType="damage-zoomed-in"
            icon={<CloseUp className="stroke-icons-color" />}
            copy={damageLandingCopy.closeUp}
            damageType="close"
          />
        )}
        {step === 1 && damageId && (
          <CaptureWrapper
            handleNext={handleNext}
            damageId={damageId}
            imgType="damage-zoomed-out"
            icon={<Context className="stroke-icons-color" />}
            copy={damageLandingCopy.context}
            damageType="context"
          />
        )}
        {step === 2 && damageId && (
          <DamageReview handleNext={handleNext} damageId={damageId} />
        )}
      </ContentView>
    </>
  );
};

export default DamageCapture;
