import flagsmith from 'flagsmith';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useClaimInfoStore } from 'src/stores/claimInfo';
import { useEntityConfigStore } from 'src/stores/entityConfig';
import { useFeatureStore } from 'src/stores/featureStore';
import texts from 'src/texts/texts';

import BaseButton from 'src/components/BaseButton/BaseButton';
import BrandingHeader from 'src/components/BrandingHeader/BrandingHeader';
import ButtonImage from 'src/components/ButtonImage/ButtonImage';
import ContentView from 'src/components/Content/Content';
import Footer from 'src/components/Footer/Footer';
import Text from 'src/components/Text/Text';

import { eventNames } from 'src/utils/events';
import { info } from 'src/utils/logger';
import * as log from 'src/utils/logger';

const RoomSubmittedPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [selected, setSelected] = useState<string | null>(null);
  const [showSurvey, setShowSurvey] = useState(false);
  const enableAIAssistance = useFeatureStore((state) => state.aiAssist);
  const returnId = useClaimInfoStore((state) => state.returnId);
  const setReturnId = useClaimInfoStore((state) => state.setReturnId);
  const surveyLink = useClaimInfoStore((state) => state.surveyLink);
  const companyLogo = useClaimInfoStore((state) => state.logo);
  const logoVerified = useClaimInfoStore((state) => state.logoVerified);
  const entityConfig = useEntityConfigStore((state) => state.config);
  const roomSubmittedCopy = useEntityConfigStore(
    (state) => state.copy.roomSubmitted
  );
  const userType = useClaimInfoStore((state) => state.userType);
  const features = useFeatureStore();

  useEffect(() => {
    if (userType === 'adjuster') {
      localStorage.setItem('returningUser', 'true');
      flagsmith.getFlags().then(() => {
        features.toggleTipsVisible(
          !flagsmith.hasFeature('hide_tips_returning_users')
        );
        features.toggleInstructionalVideo(
          flagsmith.hasFeature('instructional_video') &&
            !flagsmith.hasFeature('hide_tips_returning_users')
        );
      });
    }
    // eslint-disable-next-line
  }, []);

  const onAddAnotherRoomButtonClick = () => {
    info({
      event: eventNames.COMPLETE_ROOM,
      data: { previousRoomId: params.roomId },
    });
    navigate(`/${params.captureType}/${params.claimId}/room-select`);
  };

  const provideFeedBack = () => {
    navigate(
      `/${params.captureType}/${params.claimId}/${params.claimId}/feedback`
    );
  };

  const handleReturn = () => {
    // Reset the return id on returning to the self-service portal
    setReturnId();
    log.hosta({
      event: eventNames.CLAIM_CAPTURE_COMPLETED,
      data: {},
    });

    window.location.href =
      process.env.REACT_APP_BASE_URL + '/#/app/allroom/' + returnId;
  };

  const needSurvey = () => {
    return Boolean(surveyLink);
  };

  const handleSelect = (type: string) => (e: any) => {
    info({
      event:
        type === 'Yes'
          ? eventNames.ADD_ANOTHER_ROOM
          : eventNames.DONE_WITH_ROOM,
      data: { messsage: 'Selection' },
    });

    if (type !== 'Yes') {
      log.hosta({
        event: eventNames.CLAIM_CAPTURE_COMPLETED,
        data: {},
      });
    }

    setSelected(type);
  };

  const commonFooter = () => {
    return (
      <Footer>
        <>
          {selected !== 'Yes' && selected !== 'No' && !showSurvey && (
            <BaseButton disabled className="text-white" label="Next" />
          )}
          {selected === 'Yes' && !showSurvey && (
            <BaseButton
              dataTestId="next-add-another-room"
              onClick={onAddAnotherRoomButtonClick}
              className="text-white"
              label="Next"
            />
          )}
          {selected === 'No' && !showSurvey && (
            <BaseButton
              onClick={() => setShowSurvey(true)}
              className="text-white"
              label="complete process"
              dataTestId="complete-process-btn"
              capFirstLetterOnly={entityConfig.capFirstLetterOnly}
            />
          )}
          {showSurvey && returnId && (
            <BaseButton
              lightBg
              onClick={handleReturn}
              className="w-full mb-4 desktops:w-[70%]"
              label="done submitting rooms"
              capFirstLetterOnly={entityConfig.capFirstLetterOnly}
            />
          )}
        </>
      </Footer>
    );
  };

  if (needSurvey()) {
    return (
      <ContentView
        {...{ ...(showSurvey && { variant: 'secondary' }) }}
        className="min-h-screen p-20"
      >
        <>
          {!showSurvey && (
            <>
              <Text variant="title" testId="room-submitted-title">
                {texts.roomSubmittedAddMoreTitle}
              </Text>
              <Text variant="subtitle" extraClassname="mt-4">
                {roomSubmittedCopy.subtitle}
              </Text>
              <div className="flex flex-col gap-5 mt-10">
                <ButtonImage
                  testId="add-more-damages"
                  selected={selected === 'Yes'}
                  onClick={handleSelect('Yes')}
                  outlined
                  icon="/icons/check@2x.png"
                  label="Yes, I have another room with damage"
                  className="text-blue-400"
                />
                <ButtonImage
                  testId="no-more-damages"
                  selected={selected === 'No'}
                  onClick={handleSelect('No')}
                  outlined
                  icon="/icons/x@2x.png"
                  label="No, I don't have another room with damage"
                  className="text-blue-400"
                />
              </div>
            </>
          )}
          {showSurvey && (
            <div className="w-full flex-1 flex flex-col items-center pb-8">
              <BrandingHeader
                logo={companyLogo || ''}
                logoVerified={logoVerified}
                hideHostaBranding={entityConfig.hideHostaBranding}
              />
              <Text testId="room-submitted-title" variant="title">
                {texts.roomSubmittedPageInstruction}
              </Text>
              <Text variant="subtitle" extraClassname="mt-6">
                {roomSubmittedCopy.subtitle}
              </Text>
              <Text variant="subtitle" extraClassname="mt-12">
                {texts.roomSubmittedPageSubtitleFeedback}
              </Text>
              <BaseButton
                type="button"
                className="text-white mt-8"
                label="take Survey"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(surveyLink, '_blank');
                }}
                capFirstLetterOnly={entityConfig.capFirstLetterOnly}
              />
              {showSurvey && (
                <small className="pt-4 pb-4">
                  {texts.roomSubmittedPageClose}
                </small>
              )}
            </div>
          )}
          {commonFooter()}
        </>
      </ContentView>
    );
  }

  return (
    <ContentView className="min-h-screen pb-20">
      <>
        {!showSurvey && (
          <>
            <Text variant="title" testId="room-submitted-title">
              {texts.roomSubmittedAddMoreTitle}
            </Text>
            <Text variant="subtitle" extraClassname="mt-4">
              {roomSubmittedCopy.subtitle}
            </Text>
            <div className="flex flex-col gap-5 mt-10">
              <ButtonImage
                testId="add-more-damages"
                selected={selected === 'Yes'}
                onClick={handleSelect('Yes')}
                outlined
                icon="/icons/check@2x.png"
                label="Yes, I have another room with damage"
                className="text-blue-400"
              />
              <ButtonImage
                testId="no-more-damages"
                selected={selected === 'No'}
                onClick={handleSelect('No')}
                outlined
                icon="/icons/x@2x.png"
                label="No, I don't have another room with damage"
                className="text-blue-400"
              />
            </div>
          </>
        )}
        {showSurvey && (
          <>
            <img
              alt="Hosta Logo"
              data-testid="hosta-logo"
              className="w-24 mx-auto min-w-[150px] mb-[40px]"
              src={
                logoVerified && companyLogo
                  ? companyLogo
                  : '/images/damage/Hosta Logo.png'
              }
            />
            <Text variant="title" testId="room-submitted-title">
              {texts.roomSubmittedPageInstruction}
            </Text>
            <p className="text-center">{roomSubmittedCopy.subtitle}</p>
            {enableAIAssistance && (
              <p className="text-center mt-12 mb-4">
                Your thoughts on this experience are highly valued. Please spare
                a minute to share your thoughts.
              </p>
            )}
            {enableAIAssistance && (
              <BaseButton
                type="button"
                className="text-white"
                label="provide quick feedback"
                onClick={provideFeedBack}
                capFirstLetterOnly={entityConfig.capFirstLetterOnly}
              />
            )}
            {showSurvey && (
              <small className="pt-4 pb-4">
                {texts.roomSubmittedPageClose}
              </small>
            )}
          </>
        )}
        {commonFooter()}
      </>
    </ContentView>
  );
};

export default RoomSubmittedPage;
