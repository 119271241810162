import { PropsWithChildren } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import * as log from 'src/utils/logger';

import GenericError from '../ErrorFallback/Generic';

const ErrorElement = ({ children }: PropsWithChildren) => (
  <ErrorBoundary
    fallback={<GenericError />}
    onError={(error, info) => {
      log.error({ event: 'Rendering error', data: { error, info } });
    }}
  >
    {children}
  </ErrorBoundary>
);

export default ErrorElement;
