import './VideoTipsPage.css';
import classnames from 'classnames';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useClaimInfoStore } from 'src/stores/claimInfo';
import { useEntityConfigStore } from 'src/stores/entityConfig';
import { Swiper as ISwiper } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';

import BaseButton from 'src/components/BaseButton/BaseButton';
import ContentView from 'src/components/Content/Content';
import Footer from 'src/components/Footer/Footer';
import DamageHeader from 'src/components/Header/DamageHeader';
import Text from 'src/components/Text/Text';

import { eventNames } from 'src/utils/events';
import * as log from 'src/utils/logger';

interface ITipsProps {
  handleBack?: () => void;
}

function RoomOverview() {
  const tipsCopy = useEntityConfigStore((state) => state.copy.tips);
  const displayName = useClaimInfoStore((state) => state.displayName);
  const entityConfig = useEntityConfigStore((state) => state.config);
  const [showTips, setShowTips] = React.useState(false);
  const isRecapture = useClaimInfoStore(
    (state) => state.recaptureInfo?.isRecapture
  );

  if (showTips) {
    return <VideoTipsPage handleBack={() => setShowTips(false)} />;
  }

  return (
    <>
      <DamageHeader hideBack={isRecapture} variant="secondary" />
      <ContentView variant="secondary" className="min-h-screen">
        <div className="px-4">
          <div className="w-full desktops:w-desktop desktops:px-0 max-w-desktop pb-8">
            <div className="pb-3">
              <Text variant="title">
                {tipsCopy.overview.title.supplant({
                  displayName: displayName || 'Your insurance provider',
                })}
              </Text>
            </div>
            <Text variant="subtitle">{tipsCopy.overview.subtitle}</Text>
          </div>

          <div className="pt-15">
            <video autoPlay loop muted playsInline>
              <source src="/videos/room.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
        <Footer>
          <BaseButton
            dataTestId="start-room-capture-btn"
            onClick={() => {
              setShowTips(true);
            }}
            label="start room capture"
            capFirstLetterOnly={entityConfig.capFirstLetterOnly}
          />
        </Footer>
      </ContentView>
    </>
  );
}

function VideoTipsPage(props: ITipsProps) {
  const { handleBack } = props;
  const { claimId, roomId } = useParams();
  const navigate = useNavigate();
  const [swipeRef, setSwipeRef] = React.useState<ISwiper>();
  const [activeStep, setActiveStep] = React.useState(0);
  const entityConfig = useEntityConfigStore((state) => state.config);
  const tipsCopy = useEntityConfigStore((state) => state.copy.tips);

  const tips = [
    {
      title: tipsCopy.slide1.title,
      subtitle: tipsCopy.slide1.subtitle,
      type: 'gif',
      source: '/tips/tip_01.gif',
    },
    {
      title: tipsCopy.slide2.title,
      subtitle: tipsCopy.slide2.subtitle,
      type: 'gif',
      source: '/tips/tip_02.gif',
    },
    {
      title: tipsCopy.slide3.title,
      subtitle: tipsCopy.slide3.subtitle,
      type: 'gif',
      source: '/tips/tip_03.gif',
    },
    {
      title: tipsCopy.slide4.title,
      subtitle: tipsCopy.slide4.subtitle,
      type: 'video',
      source: '/videos/pinch.mp4',
    },
  ];

  const handleNext = () => {
    log.event({ event: eventNames.NEXT_TIP, data: 'Tip ' + (activeStep + 1) });
    if (activeStep === 3) {
      navigate('../capture-room');
      return;
    }
    swipeRef?.slideNext();
  };

  const handlePrev = () => {
    if (activeStep === 0) {
      handleBack?.();
      return;
    }
    swipeRef?.slidePrev();
  };

  const handleSkip = () => {
    log.event({ event: eventNames.SKIP_TIPS, data: { claimId, roomId } });
    navigate('../capture-room');
  };

  return (
    <>
      <DamageHeader
        handleBack={handlePrev}
        variant="secondary"
        buttonRight={
          <div
            data-testid="skip-tips"
            className={classnames('text-sm text-icons-color p-2', {
              capitalize: !entityConfig.capFirstLetterOnly,
              'first-letter:capitalize': entityConfig.capFirstLetterOnly,
            })}
            onClick={handleSkip}
          >
            Skip tips
          </div>
        }
      />
      <ContentView variant="secondary" className="min-h-screen">
        <div className="w-full flex-1 flex flex-col items-center pb-48">
          <div className="w-full desktops:w-desktop px-2 desktops:px-0 max-w-desktop flex flex-col items-center flex-1">
            <Swiper
              onInit={(s: any) => setSwipeRef(s)}
              onSlideChange={(s: any) => setActiveStep(s.activeIndex)}
              className="mySwiper"
            >
              {tips.map((tip, index) => (
                <SwiperSlide style={{ background: 'transparent' }} key={index}>
                  <div className="flex flex-col gap-5">
                    <Text variant="title">{tip.title}</Text>
                    {tip.type === 'gif' && (
                      <div>
                        <img alt="Tip Gif" src={tip.source} />
                      </div>
                    )}
                    {tip.type === 'video' && (
                      <div>
                        <video autoPlay loop muted playsInline>
                          <source src={tip.source} type="video/mp4" />
                        </video>
                      </div>
                    )}
                    <h4 className="bg-transparent">{tip.subtitle}</h4>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <p>
            {activeStep + 1} of {swipeRef?.slides?.length}
          </p>
        </div>
        <Footer>
          <BaseButton
            dataTestId="next-tips-btn"
            name="Next Tip"
            onClick={handleNext}
            label="Next"
          />
        </Footer>
      </ContentView>
    </>
  );
}

export default RoomOverview;
