import claimId from './common/claimId';
import submitter from './common/submitter';
import { useClaimInfoStore } from 'src/stores/claimInfo';

export type RoomSubmittedImages = {
  total: number;
  images: [
    {
      id: number;
      key: string;
    },
  ];
};

export type RoomSubmittedData = {
  roomId: string;
  damageId: string;
  setName: string;
  totalTimeSeconds: number;
  wallImages: RoomSubmittedImages;
  damageImages: RoomSubmittedImages;
};

const roomSubmitted = (d: any) => {
  const claimInfo = useClaimInfoStore.getState();
  const wallImages = d.uploaded.filter(
    (img: any) =>
      img.imgType === 'overviewImage' || img.imageField === 'overviewImages'
  );

  const damageImages = d.uploaded.filter((img: any) =>
    (img?.imgType || img?.type || '').includes('zoomed')
  );

  const data: RoomSubmittedData = {
    ...d,
    wallImages: {
      total: wallImages.length,
      images: wallImages.map((img: any) => ({
        id: img.id,
        key: img.key || img.awsKey,
      })),
    },
    damageImages: {
      total: damageImages.length,
      images: damageImages.map((img: any) => ({
        id: img.id,
        key: img.key || img.awsKey,
      })),
    },
    damageId: damageImages?.[0]?.damageId || '',
  };

  return [
    submitter(),
    claimId(),
    {
      type: 'context',
      elements: [
        {
          type: 'mrkdwn',
          text: `*Room id:*\n<${process.env.REACT_APP_BASE_URL}/#/app/processingInterface/${claimInfo.claimId}/${data.roomId}|${data.roomId}>`,
        },
      ],
    },
    {
      type: 'divider',
    },
    {
      type: 'section',
      fields: [
        {
          type: 'mrkdwn',
          text: `*Wall images: ${data.wallImages.total}*`,
        },
      ],
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: data.wallImages.images.length
          ? data.wallImages.images
              .map((img: any) => `*${img.id} -* ${img.key}`)
              .join('\n')
          : '*N/A*',
      },
    },
    {
      type: 'section',
      fields: [
        {
          type: 'mrkdwn',
          text: `*Damage images: ${data.damageImages.total}*`,
        },
      ],
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: data.damageImages.images.length
          ? data.damageImages.images
              .map((img: any) => `*${img.id} -* ${img.key}`)
              .join('\n')
          : '*N/A*',
      },
    },
    {
      type: 'context',
      elements: [
        {
          type: 'mrkdwn',
          text: `*${data.setName}:*\n ${data.damageId}`,
        },
      ],
    },
    {
      type: 'section',
      fields: [
        {
          type: 'mrkdwn',
          text: `*Total images: ${data.damageImages.total + data.wallImages.total}*`,
        },
        {
          type: 'mrkdwn',
          text: `*Time to upload: ${data.totalTimeSeconds}s*`,
        },
      ],
    },
  ];
};

export default roomSubmitted;
