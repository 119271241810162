import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';

import HelpContext from 'src/components/HelpModal';
import Text from 'src/components/Text/Text';

interface LeftPhotoProps {
  title: string | undefined;
  subtitle: string | undefined;
  categories: { type: string; video?: string }[];
  type: string | null;
  roomType: {
    left: string;
    right: string;
  };
  handleLeftPhoto: () => void;
  handleOnePhoto: () => void;
}

const LeftPhoto: React.FC<LeftPhotoProps> = ({
  title,
  subtitle,
  categories,
  type,
  roomType,
  handleLeftPhoto,
  handleOnePhoto,
}) => {
  const [showTip1, setShowTip1] = useState(false);
  const [showTip2, setShowTip2] = useState(false);
  const [tipsCount, setTipsCount] = useLocalStorage<number>('tipsCount', 0);

  useEffect(() => {
    setShowTip1(false);
    setShowTip2(false);
  }, []);

  const hideTips = () => {
    setShowTip1(false);
    setShowTip2(false);
  };

  const captureLeft = () => {
    hideTips();
    handleLeftPhoto();
  };

  const captureOne = () => {
    hideTips();
    handleOnePhoto();
  };

  const showTip = (tipNumber: number) => {
    if (tipsCount >= 2) {
      if (tipNumber === 1) {
        captureLeft();
      } else {
        captureOne();
      }
      return;
    }
    setTipsCount(tipsCount + 1);
    if (tipNumber === 1) {
      setShowTip1(true);
    }
    if (tipNumber === 2) {
      setShowTip2(true);
    }
  };

  return (
    <div className="flex flex-col h-full justify-between">
      <div>
        <Text variant="title">{title}</Text>
        <Text align="left" variant="paragraph">
          {subtitle}
        </Text>
        <video autoPlay loop muted playsInline>
          <source
            src={categories.find((e) => e.type === type)?.video}
            type="video/mp4"
          />
        </video>
      </div>
      <div className="flex flex-col w-full gap-2">
        <HelpContext
          open={showTip1}
          onClose={() => setShowTip1(false)}
          onSubmit={captureLeft}
        />
        <HelpContext
          open={showTip2}
          onClose={() => setShowTip2(false)}
          onSubmit={captureOne}
        />
        <Button onClick={() => showTip(1)} variant="contained">
          Take {roomType.left}
        </Button>
        <Button onClick={() => showTip(2)} variant="outlined">
          Take With One Photo
        </Button>
      </div>
    </div>
  );
};

export default LeftPhoto;
