import * as Sentry from '@sentry/react';
import { useLiveQuery } from 'dexie-react-hooks';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEntityConfigStore } from 'src/stores/entityConfig';
import { useUploadQueueStore } from 'src/stores/uploadQueue';

import PhotoCapture from 'src/pages/Damage/DamageCapture/components/PhotoCapture/PhotoCapture';
import { dexieDb } from 'src/utils/indexedDb';

import BaseButton from 'src/components/BaseButton/BaseButton';
import Footer from 'src/components/Footer/Footer';

import { eventNames } from 'src/utils/events';
import * as log from 'src/utils/logger';

type Copy = {
  title: string;
  subtitle: string;
  heading: string;
  takePhotoBtn: string;
};

type Props = {
  damageType: 'context' | 'close';
  handleNext: () => void;
  damageId: string;
  imgType: string;
  icon: React.ReactElement;
  copy: Copy;
};

const getDamagePictures = async (
  roomId = '',
  damageId: string,
  imgType: string
) =>
  dexieDb.pictures
    .where('roomId')
    .equals(roomId)
    .and((p) => p.imgType === imgType && p.damageId === damageId)
    .toArray();

const CaptureWrapper = ({
  damageId,
  handleNext,
  imgType,
  icon,
  copy,
  damageType,
}: Props) => {
  const { roomId } = useParams();
  const [image, setImage] = useState<string | null>(null);
  const entityConfig = useEntityConfigStore((state) => state.config);
  const addImgToQueue = useUploadQueueStore((state) => state.add);

  const pictures = useLiveQuery(
    () => getDamagePictures(roomId, damageId, imgType),
    [roomId]
  );

  useEffect(() => {
    if (pictures?.length) {
      setImage(pictures?.[0]?.content);
    }
  }, [pictures]);

  const handleCapture = async (data: string) => {
    try {
      if (pictures?.length) {
        await dexieDb.pictures.delete(pictures[0].id);
      }
      const images = await dexieDb.pictures
        .where('roomId')
        .equals(roomId || '')
        .toArray();
      const maxId = Math.max(...images.map((e) => e.id), 17) + 1;
      await dexieDb.pictures.put({
        id: maxId,
        damageId,
        content: data,
        uploadId: maxId,
        roomId,
        imgType: imgType,
      });

      navigator.onLine &&
        addImgToQueue({ id: maxId, abortController: new AbortController() });

      log.hosta({
        event:
          imgType === 'damage-zoomed-in'
            ? eventNames.DAMAGE_CLOSE_UP_CAPTURED
            : eventNames.DAMAGE_CONTEXT_CAPTURED,
        data: {},
      });
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
    }
  };

  return (
    <>
      <PhotoCapture
        icon={icon}
        title={copy.title}
        image={image}
        subtitle={copy.subtitle}
        imageType={copy.heading.toLowerCase()}
        handleCapture={handleCapture}
      />
      <Footer extraClassname="border-t bg-white pt-4">
        {!pictures?.length && (
          <BaseButton
            lightBg
            type="capture"
            dataTestId={`${damageType}-photo-capture`}
            onCapture={handleCapture}
            icon="/icons/camera@2x.png"
            label={copy.takePhotoBtn}
            capFirstLetterOnly={entityConfig.capFirstLetterOnly}
          />
        )}
        {!!pictures?.length && (
          <BaseButton
            dataTestId="damage-next"
            onClick={handleNext}
            label="Next"
            lightBg
          />
        )}
      </Footer>
    </>
  );
};

export default CaptureWrapper;
