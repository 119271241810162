import { create } from 'zustand';

interface FeatureState {
  aiAssist: boolean;
  enableDamage: boolean;
  inBrowserCapture: boolean;
  instructionalVideo: boolean;
  collectContactInfo: boolean;
  collectRoomName: boolean;
  tipsVisible: boolean;
  newSubmitLoader: boolean;
  toggleAiAssist: (val: boolean) => void;
  toggleDamage: (val: boolean) => void;
  toggleInBrowserCapture: (val: boolean) => void;
  toggleInstructionalVideo: (val: boolean) => void;
  toggleCollectContactInfo: (val: boolean) => void;
  toggleCollectRoomName: (val: boolean) => void;
  toggleTipsVisible: (val: boolean) => void;
  toggleNewSubmitLoader: (val: boolean) => void;
}

export const useFeatureStore = create<FeatureState>()((set) => ({
  aiAssist: false,
  enableDamage: false,
  inBrowserCapture: false,
  instructionalVideo: false,
  collectContactInfo: false,
  collectRoomName: false,
  tipsVisible: true,
  newSubmitLoader: false,
  toggleAiAssist: (val: boolean) => set((state) => ({ aiAssist: val })),
  toggleDamage: (val: boolean) => set((state) => ({ enableDamage: val })),
  toggleTipsVisible: (val: boolean) => set((state) => ({ tipsVisible: val })),
  toggleCollectContactInfo: (val: boolean) =>
    set((state) => ({ collectContactInfo: val })),
  toggleCollectRoomName: (val: boolean) =>
    set((state) => ({ collectRoomName: val })),
  toggleInBrowserCapture: (val: boolean) =>
    set((state) => ({ inBrowserCapture: val })),
  toggleInstructionalVideo: (val: boolean) =>
    set((state) => ({ instructionalVideo: val })),
  toggleNewSubmitLoader: (val: boolean) =>
    set((state) => ({ newSubmitLoader: val })),
}));
