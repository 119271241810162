import * as Sentry from '@sentry/react';
import type { AxiosError } from 'axios';
import mixpanel from 'mixpanel-browser';
import { useState } from 'react';
import { validateLink } from 'src/REST/capture';
import { useClaimInfoStore } from 'src/stores/claimInfo';
import type { State as ClaimInfo } from 'src/stores/claimInfo';
import UAParser from 'ua-parser-js';
import { useEffectOnce, useLocalStorage } from 'usehooks-ts';
import { v4 as uuid } from 'uuid';

import * as log from 'src/utils/logger';
import { phoneModels } from 'src/utils/phoneModels';

const useValidateLink = (linkId: string) => {
  const [data, setData] = useState<ClaimInfo | null>(null);
  const [error, setError] = useState<string | null>(null);
  const parser = new UAParser();
  // Store
  const verifyLogo = useClaimInfoStore((state) => state.verifyLogo);
  const setClaimInfo = useClaimInfoStore((state) => state.setClaimInfo);
  // Local Storage Copy
  const [claimInfo, setLocalClaimInfo] = useLocalStorage<ClaimInfo | null>(
    'claimData',
    null
  );

  const modelByResolution = (vendor: string | undefined) => {
    if (vendor) {
      const resolution = `${window.screen.width} x ${window.screen.height}`;
      return (
        phoneModels[vendor].find((e) => {
          return e.resolution === resolution;
        })?.model || 'Unknown Device'
      );
    }
    return 'Unknown Device';
  };

  useEffectOnce(() => {
    (async () => {
      try {
        const ua = navigator.userAgent;
        parser.setUA(ua);
        const result = parser.getResult();
        const { data } = navigator.onLine
          ? await validateLink(linkId)
          : { data: claimInfo };

        setData({ ...data });
        setClaimInfo({ ...data });
        setLocalClaimInfo(data);
        data.logo && verifyLogo(data.logo);

        mixpanel.register({
          environment: process.env.REACT_APP_ENV,
          appVersion: process.env.REACT_APP_VERSION,
          ...result.device,
          deviceName: modelByResolution(result.device.vendor),
          sessionId: uuid(),
          ...data,
        });

        delete data.logo;

        Sentry.setContext('ClaimInfo', data);

        log.success({
          event: 'Check for Valid Link',
          data: {
            header: 'Link is valid',
            template: 'validLink',
            ...data,
          },
        });
      } catch (e) {
        const err = e as AxiosError;
        setError((err?.response?.data as string) || 'Unknown Network Error');
        log.error({
          event: 'Check for Valid Link',
          ignoreSlack: false,
          data: {
            header: 'Link is invalid',
            error: e || `Unknown Network Error for LinkId ${linkId}`,
          },
        });
      }
    })();
  });

  return { data, error };
};

export default useValidateLink;
