import React from 'react';
import { v4 as uuid } from 'uuid';

import { dexieDb } from 'src/utils/indexedDb';

import ContentView from 'src/components/Content/Content';
import Footer from 'src/components/Footer/Footer';

export const HomePage = () => {
  const handleClear = () => {
    localStorage.clear();
    sessionStorage.clear();
    dexieDb.pictures.clear();
    alert('Application Data Cleared!');
  };

  return (
    <>
      <ContentView>
        <>
          <div className="w-full">
            <img
              alt="Hosta Logo"
              className="w-24 mx-auto min-w-[150px] my-[40px]"
              src="/images/damage/Hosta Logo.png"
            />
          </div>
          <div>
            <p className="text-2xl text-black font-medium text-center">
              Welcome to Hosta!
            </p>
          </div>
          <div>
            <p className="text-xl font-light text-center py-5">
              Super fast inspection. Detailed Documentation
            </p>
          </div>
          <div>
            <small>
              Please{' '}
              <a
                rel="noreferrer"
                target="_blank"
                className="text-blue-500 underline"
                href={`#/test/${uuid()}`}
              >
                click here
              </a>{' '}
              to see it in action
            </small>
          </div>
          <Footer>
            <button
              className="text-xs border-2 cursor-pointer p-2 rounded-lg hover:bg-slate-100"
              onClick={handleClear}
            >
              Clear Data
            </button>
          </Footer>
        </>
      </ContentView>
    </>
  );
};

export default HomePage;
