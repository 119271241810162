import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type Address = {
  address1: string;
  address2: string;
  city: string;
  claimDate: string;
  country: string;
  customerID: string;
  damageType: string;
  notes: string;
  state: string;
  zip: string;
};

export type RoomInfo = {
  roomType: string;
  rejectionCount: number;
  isRecapture: boolean;
  status: string;
};

export type State = {
  claimId: string;
  allowDamage: boolean;
  claimInfo: Address | null;
  customerId: string;
  displayName: string;
  entity: string;
  linkCreationTime: number;
  logo: string | null;
  submitter: string;
  surveyLink: string;
  logoVerified: boolean;
  returnId: string | null;
  enableAIAssistance: boolean;
  recaptureInfo?: RoomInfo;
  userType: 'adjuster' | 'unknown' | 'xactimate';
  contactInfo?: string;
  captureId?: string;
  roomId?: string;
  linkId?: string;
  isRoomSubmitted: boolean;
};

type ClaimInfoStore = State & {
  verifyLogo: (logo: string) => void;
  setReturnId: (id?: string) => void;
  setClaimInfo: (data: State) => void;
  setRecaptureInfo: (data: RoomInfo) => void;
  setContactInfo: (info: string) => void;
  setCaptureId: (id: string) => void;
  setRoomId: (id: string) => void;
  setLinkId: (id: string) => void;
  setIsRoomSubmitted: (isSubmitted: boolean) => void;
};

export const useClaimInfoStore = create<ClaimInfoStore>()(
  persist(
    (set) => ({
      allowDamage: true,
      claimId: '',
      claimInfo: null,
      customerId: '',
      displayName: '',
      entity: '',
      linkCreationTime: 0,
      logo: null,
      submitter: '',
      surveyLink: '',
      logoVerified: false,
      returnId: null,
      enableAIAssistance: false,
      userType: 'unknown',
      captureId: '',
      isRoomSubmitted: false,
      setClaimInfo: (data: State) =>
        set((state) => ({
          ...state,
          ...data,
          userType: data.userType || 'unknown',
        })),
      verifyLogo: (logo) => {
        fetch(logo)
          .then((res) => {
            if (res.ok) {
              set((state: ClaimInfoStore) => ({
                ...state,
                logoVerified: true,
              }));
            }
          })
          .catch((ex) => {
            set((state: ClaimInfoStore) => ({ ...state, logoVerified: true }));
            console.error(ex);
          });
      },
      setReturnId: (id) =>
        set((state) => ({ ...state, returnId: id ? id : null })),
      setRecaptureInfo: (data: RoomInfo) =>
        set((state) => ({ ...state, recaptureInfo: data })),
      setContactInfo: (info) =>
        set((state) => ({ ...state, contactInfo: info })),
      setCaptureId: (id) => set((state) => ({ ...state, captureId: id })),
      setRoomId: (id) => set((state) => ({ ...state, roomId: id })),
      setLinkId: (id) => set((state) => ({ ...state, linkId: id })),
      setIsRoomSubmitted: (isSubmitted) =>
        set((state) => ({ ...state, isRoomSubmitted: isSubmitted })),
    }),
    {
      name: 'claimInfo',
      partialize: (state) => ({
        // Persist returnId only in case of refresh
        returnId: state.returnId,
        captureId: state.captureId,
        roomId: state.roomId,
        isRoomSubmitted: state.isRoomSubmitted,
      }),
    }
  )
);
