import DrawingsSVG from './image';
import MissingWall from './missing';
import './slider.css';
import uniqueId from 'lodash/uniqueId';
import React, { useRef } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Pagination, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const ImageSlider = ({
  images,
  onChange,
  slideNumber = 0,
  navigation = false,
}: {
  images: any[];
  slideNumber?: number;
  onChange: (e: any) => void;
  navigation?: boolean;
}) => {
  return (
    <Swiper
      loop={images.length > 1}
      initialSlide={slideNumber}
      navigation={navigation}
      spaceBetween={30}
      slidesPerView={1}
      centeredSlides
      pagination
      onSlideChange={onChange}
      modules={[Pagination, Navigation]}
      className="w-full h-full"
    >
      {images?.map((item) => (
        <SwiperSlide key={uniqueId()}>
          {item.type === 'regular' ? (
            <ImageItem item={item} />
          ) : (
            <MissingWall left={item.leftImage} right={item.rightImage} />
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

const ImageWithLoading = ({
  src,
  alt,
  svg,
}: {
  src: string;
  alt: string;
  svg: string | null | undefined;
}) => {
  return (
    <div className="relative w-full h-64 flex items-center justify-center">
      <DrawingsSVG svg={svg} image={src} />
    </div>
  );
};

const ImageItem = ({ item }: { item: any }) => {
  const imageRef = useRef(null);

  return (
    <div ref={imageRef} className="image-container min-h-72">
      <ImageWithLoading svg={item.graphics} src={item.imageUrl} alt="Slide" />
    </div>
  );
};

export default ImageSlider;
